"use client";
import React, { ReactElement, useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import Link from "@components/shared/linkNoPrefetch";
import { GQLUrls } from "@gql/types/gql";
import styles from "./style.module.scss";
import SeveralLogo from "./logo";
import Burger from "./burger";
import { useRouter } from "next/router";
import { useMemo } from "react";
import languages from "@data/languages.json";
import LanguageListItem from "./languageListItem";
import { getUrl } from "@helpers/switchLanguage";
import {
    ICustomDomain,
    Languages,
    LanguagesData,
} from "@genericTypes/sharedTypes";
import LanguageSwitcher from "./LanguageSwitcher";
import useWindowSize from "@hooks/useWindowSize";
import { useAtom } from "jotai";
import { activePageAtom } from "@store/generalStore";
import SearchButton from "./SearchButton";

export default function Header(): ReactElement {
    const [isOpened, setOpened] = useState(false);
    const { data: headerLinks } = useQuery<ICustomDomain, Error>({
        queryKey: ["domain"],
    });
    const staticPages = [
        "/",
        "/categories",
        "/blog",
        "/about-us",
        "/partner-with-us",
        "/privacy-policy",
        "/contact-us",
        "/news",
    ];

    const [activePage] = useAtom(activePageAtom);
    const router = useRouter();

    const windowSize = useWindowSize();
    const menuHeaderLinks = headerLinks?.domain?.menus?.find(
        (el) => el?.slug === "several-header",
    )?.links;
    const [selectedLang, setSelectedLang] = useState(
        process.env.NEXT_PUBLIC_DEFAULT_LANG as string,
    );
    const langs: LanguagesData = useMemo(() => {
        return languages.data;
    }, [languages.data]);

    const handleLanguageChange = (e: MouseEvent) => {
        //@ts-ignore
        const { id }: { id: string } = e.target;
        setSelectedLang(id);
        const currentLanguage = process.env.NEXT_PUBLIC_DEFAULT_LANG;
        if (currentLanguage === id) return;
        void router.push(getUrl(id as Languages, router.asPath));
    };
    const { data } = useQuery<GQLUrls[]>(["allUrls"]);
    const [links, setLinks] = useState<GQLUrls[]>(
        data ?? [
            {
                isMain: true,
                url: router.asPath,
                model: "static",
            },
        ],
    );

    useEffect(() => {
        if (!data || staticPages.includes(router.asPath)) {
            setLinks([
                {
                    isMain: true,
                    url: router.asPath,
                    model: "static",
                },
            ]);
        } else {
            setLinks(data);
        }
    }, [router.asPath]);

    return (
        <header className={`${styles.header} pb-2 md:pb-0`}>
            <div className="flex justify-between items-center h-11 lg1048:h-24 max-w-7xl mx-auto px-5">
                <div className={`${styles.severalLogo}`}>
                    <Link href="/" className="block" title="several.com">
                        <SeveralLogo />
                    </Link>
                </div>
                {windowSize.width <= 1048 && (
                    <div className="flex gap-5 items-center">
                        {activePage.toLocaleLowerCase() !== "search" && (
                            <div className="relative md:hidden block mt-[3px] hover:text-[#F9BB21] border-b-[2px] border-b-transparent hover:border-b-[2px] hover:border-b-[#F9BB21]">
                                <SearchButton />
                            </div>
                        )}

                        <Burger
                            isOpened={isOpened}
                            setOpened={(x) => {
                                setOpened(x);
                            }}
                        />
                    </div>
                )}
                <div
                    className={`overflow-auto ${styles.menu} ${
                        isOpened ? styles.open : ""
                    }`}
                >
                    <ul className="flex justify-center items-center flex-col lg1048:flex-row pt-28 lg1048:pt-0 lg1048:items-center sm:text-sm text-xl">
                        {menuHeaderLinks?.map((el, index) => (
                            <li
                                className={
                                    index === menuHeaderLinks.length - 1
                                        ? "sm:pl-6"
                                        : "px-6"
                                }
                                key={el?.id}
                                onClick={() => {
                                    setOpened(false);
                                }}
                            >
                                <Link
                                    href={el?.link as string}
                                    className={`${
                                        el?.title?.toLocaleLowerCase() ===
                                        activePage.toLocaleLowerCase()
                                            ? styles.active
                                            : styles.link
                                    }  block py-2  m-1 font-extrabold lg1048:font-normal hover:text-[#F9BB21] transition text-white hover:no-underline `}
                                >
                                    {el?.title}
                                </Link>
                            </li>
                        ))}
                        {activePage.toLocaleLowerCase() !== "search" && (
                            <li
                                className={`hidden md:block py-2 px-6 ml-6 m-1 hover:text-[#F9BB21] transition cursor-pointer ${styles.search}`}
                            >
                                <SearchButton />
                            </li>
                        )}
                        <div className="sm:hidden w-[50%] border border-[#F9BB21] mt-5 mb-5"></div>

                        <p
                            className={`lg1048:hidden m-1 sm:pl-6 pl-0 flex flex-col gap-4 text-xl pb-4 font-extrabold text-white hover:no-underline `}
                        >
                            language
                        </p>

                        <LanguageSwitcher
                            options={langs}
                            selectedItem={selectedLang}
                            //@ts-ignore
                            onChange={handleLanguageChange}
                            links={links}
                        />

                        {windowSize.width <= 1048 && isOpened && (
                            <ul
                                className={`lg1048:hidden flex flex-col items-center justify-center m-1 sm:pl-6 pl-0 gap-4 text-base  text-white hover:no-underline `}
                            >
                                {Object.values(langs).map((lang) => (
                                    <LanguageListItem
                                        key={lang.iso}
                                        lang={lang.iso}
                                        label={lang.label}
                                    />
                                ))}
                            </ul>
                        )}
                    </ul>
                </div>
            </div>
        </header>
    );
}
