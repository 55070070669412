import React, { ReactElement } from "react";

export default function FavIcons(): ReactElement {
    return (
        <>
            <link
                rel="apple-touch-icon"
                sizes="114x114"
                href="/favIcon/apple-touch-icon.png"
            />
            <link
                rel="icon"
                type="image/png"
                sizes="32x32"
                href="/favIcon/favicon-32x32.png"
            />
            <link
                rel="icon"
                type="image/png"
                sizes="16x16"
                href="/favIcon/favicon-16x16.png"
            />
            <link rel="manifest" href="/favIcon/site.webmanifest" />
            <link rel="icon" href="favicon.ico" type="image/x-icon" />
            <link rel="shortcut icon" href="favicon.ico" type="image/x-icon" />

            <meta name="msapplication-TileColor" content="#da532c" />
            <meta name="theme-color" content="#ffffff" />
        </>
    );
}
