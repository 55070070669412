import {
    GQLCategories,
    GQLCategoryAttributes,
    GQLCategoryAttributesValues,
    GQLDkis,
    GQLLookupsByIdsQuery,
    GQLOffers,
    GQLUrls,
    Maybe,
} from "@gql/types/gql";
import { VisitorInfo, WorkingHoursType } from "@genericTypes/sharedTypes";
import { GQLDomains } from "@gql/types/gql";
import parse from "html-react-parser";
import { ArgsType } from "@genericTypes/sharedTypes";
import { getFromStorage, saveToStorage } from "@helpers/storageHelper";
import { Session } from "pages/api/session";
import Facebook from "@components/shared/sideWidget/icons/Facebook";
import FacebookMobile from "@components/shared/sideWidget/icons/FacebookMobile";
import Twitter from "@components/shared/sideWidget/icons/Twitter";
import TwitterMobile from "@components/shared/sideWidget/icons/TwitterMobile";
import Pinterest from "@components/shared/sideWidget/icons/pinterest";
import PinterestMobile from "@components/shared/sideWidget/icons/PinterestMobile";
export const PLACE_HOLDER_IMAGE_PATH = "/placeholder_several.jpg";
export const DISCARDED_ATTRIBUTES = [
    "stickyOfferMessage",
    "stickyOfferBtnLabel",
    "phoneNumber",
    "phoneNumberMessage",
    "Phone Number",
    "link",
];
export const formatPhoneNumber = (phoneNumber: string | undefined) => {
    if (!phoneNumber) return null;
    const cleaned = ("" + phoneNumber).replace(/\D/g, "");
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return ["(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return null;
};

export const getDate = (date: string) => {
    const dateArr = date.split("/");
    const monthArr = [
        "january",
        "february",
        "march",
        "abril",
        "may",
        "june",
        "july",
        "august",
        "september",
        "october",
        "december",
    ];
    return `${monthArr[parseInt(dateArr[0], 10) - 1]} ${dateArr[1]},${
        dateArr[2]
    }`;
};
export const getDay = (day: number) => {
    const dayArr = [
        "sunday",
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
    ];
    return `${dayArr[day]}`;
};
export function isLink(link: Maybe<string> | undefined) {
    if (link) {
        return link.startsWith("http");
    }
    return false;
}
export const hasAffiliateLink = (vendor: GQLOffers) => {
    return vendor?.defaultAffiliateLink !== null;
};

export const getPhoneNumber = (offer: GQLOffers) => {
    if (hasAffiliateLink(offer) && !isLink(offer?.defaultAffiliateLink?.link)) {
        return offer?.defaultAffiliateLink?.link;
    }

    const phoneNumberAttribute = offer.attributes?.find(
        (attribute) => attribute?.baseAttribute?.name === "phoneNumber",
    );

    return phoneNumberAttribute
        ? (phoneNumberAttribute.value as string)
        : undefined;
};

export const isWithinSchedule = (
    workingHours: WorkingHoursType | null | undefined,
) => {
    if (!workingHours?.isActive || workingHours === null) {
        return true;
    }

    const buyerTime = new Date(
        new Date().toLocaleString("en-US", {
            timeZone: workingHours.timeZone,
        }),
    );

    const time = `${buyerTime.getHours()}:${
        buyerTime.getMinutes() < 10
            ? `0${buyerTime.getMinutes()}`
            : buyerTime.getMinutes()
    }:${
        buyerTime.getSeconds() < 10
            ? `0${buyerTime.getSeconds()}`
            : buyerTime.getSeconds()
    }`;
    const day = getDay(buyerTime.getDay());

    const workAtThatDay = workingHours.schedule.find((el) => el.name === day);

    if (workAtThatDay?.isActive) {
        const workArr = workAtThatDay.time.map((el) => {
            return (
                parseInt(el.start.replace(/:/g, ""), 10) <=
                    parseInt(time.replace(/:/g, ""), 10) &&
                parseInt(el.end.replace(/:/g, ""), 10) >=
                    parseInt(time.replace(/:/g, ""), 10)
            );
        });

        if (workArr.includes(true)) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
};

export const getFirstParagraphFormPost = (body: string | undefined | null) => {
    const unfilteredChildren = parse(body ? body : "", {
        trim: true,
    });

    let children: JSX.Element[] = [];

    if (Array.isArray(unfilteredChildren)) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        children = unfilteredChildren?.find((el) => el.type === "p")?.props
            .children;
    }

    let content = Array.isArray(children) ? children[0] : children;
    while (typeof content !== "string") {
        //@ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        content = content.props.children;
    }

    return content;
};

export const featureListOfferValueHandler = (value: string | undefined) => {
    const newValue = value?.toLowerCase();
    const availableValues = ["true", '"true"', true];
    const notAvailableValues = ["false", '"false"', false, ""];
    if (availableValues.includes(newValue as string)) {
        return "Available";
    }
    if (notAvailableValues.includes(newValue as string)) {
        return "Not Available";
    }
    return value;
};
const getOfferAttributes = (offer: GQLOffers, groupId: number) => {
    const offerAttributes: { label: string; value: string; weight: number }[] =
        [];

    offer.attributes
        ?.filter(
            (offer) =>
                (offer?.baseAttribute?.comparison ||
                    offer?.baseAttribute?.forceComparisonsDisplay) &&
                offer?.baseAttribute?.attributesGroup?.id === groupId,
        )
        .forEach((offerElem) => {
            if (offerElem?.baseAttribute?.forceComparisonsDisplay) {
                offerAttributes.push({
                    label: offerElem?.value as string,
                    value: "text",
                    weight: offerElem?.baseAttribute?.weight as number,
                });
            } else {
                offerAttributes.push({
                    label: offerElem?.baseAttribute?.name as string,
                    value: offerElem?.value as string,
                    weight: offerElem?.baseAttribute?.weight as number,
                });
            }
        });

    return offerAttributes;
};

const getSharedCategoryOfferAttributes = (
    category: GQLCategories,
    offerAttribute: { label: string; value: string }[],
    groupId: number,
) => {
    const offerAttributesLabels = offerAttribute.map(
        (offerAttributeElem) => offerAttributeElem.label,
    );
    const shareAttribute: {
        label: string;
        value: string | undefined;
        weight: number;
    }[] = [];
    category.attributes?.forEach((attribute) => {
        if (
            attribute?.attributesGroup?.id === groupId &&
            attribute?.type === "Boolean" &&
            !offerAttributesLabels.includes(attribute.name as string)
        ) {
            shareAttribute.push({
                label: attribute.name as string,
                value: undefined,
                weight: attribute.weight as number,
            });
        }
    });
    return shareAttribute;
};

export const getAttributesComparisons = (
    category: GQLCategories,
    offer: GQLOffers,
    groupId: number,
) => {
    const offerAttribute = getOfferAttributes(offer, groupId) ?? [];

    const sharedCategoryAttribute: {
        label: string;
        value: string | undefined;
        weight: number;
    }[] =
        getSharedCategoryOfferAttributes(category, offerAttribute, groupId) ??
        [];

    const finalAttributes = [...sharedCategoryAttribute, ...offerAttribute];
    const sortedFinalAttributes = finalAttributes.sort(function (a, b) {
        if (a.weight === b.weight) {
            return a.label.localeCompare(b.label); // sort alphabetically
        }
        return (b?.weight ?? 0) - (a?.weight ?? 0);
    });
    return sortedFinalAttributes;
};
interface Group {
    [x: string]: {
        slug: string;
        name: string;
        order: number;
        id: number;
    };
}
export const getCategorySlug = (slug: string) => {
    return slug === "point-of-sale-systems" ? "point-of-sale" : slug;
};
export const getCategorySlugAlias = (slug: string) => {
    return slug === "point-of-sale-systems" || slug === "point-of-sale"
        ? "pos"
        : slug;
};
export const getAllGroupsFromAttributes = (category: GQLCategories) => {
    const groupObject: Group = {};

    const excludedGroups: string[] = [];

    category?.attributes?.forEach((attribute: Maybe<GQLCategoryAttributes>) => {
        if (
            attribute?.attributesGroup?.isActive &&
            attribute?.attributesGroup?.slug &&
            !excludedGroups.includes(attribute?.attributesGroup?.name as string)
        ) {
            groupObject[attribute?.attributesGroup?.slug] = {
                slug: attribute?.attributesGroup?.slug,
                name: attribute?.attributesGroup?.name as string,
                order: attribute?.attributesGroup?.order as number,
                id: attribute?.attributesGroup?.id as number,
            };
        }
    });
    return Object.keys(groupObject)
        .map((key) => groupObject[key])
        .sort((a, b) => a.order - b.order);
};
export const getDescriptionForMetaTags = (
    domain: GQLDomains,
    fallback?: string,
) => {
    if (!domain?.metatags) {
        return fallback;
    }

    for (let i = 0; i < (domain?.metatags?.length ?? 0); i++) {
        if (
            domain?.metatags[i]?.key === "description" &&
            domain?.metatags[i]?.value
        ) {
            return domain?.metatags[i]?.value;
        }
    }
    if (domain?.description) {
        return domain.description.replace(/<[^>]+>/g, "");
    }

    return fallback;
};
export const getKeyWordsForMetaTags = (domain: GQLDomains) => {
    if (!domain?.metatags) {
        return "vpn , auto warranty";
    }

    for (let i = 0; i < (domain?.metatags?.length ?? 0); i++) {
        if (
            domain?.metatags[i]?.key === "keywords" &&
            domain?.metatags[i]?.value
        ) {
            return domain?.metatags[i]?.value;
        }
    }

    return "vpn , auto warranty";
};
export const getMainTitlesNavigationFromBody = (html: string) => {
    const regex = /<[^>]+ id="([^"]+)"[^>]*>([\s\S]+?)<\/[^>]+>/g;
    const result = [];

    let match;
    while ((match = regex.exec(html))) {
        const [, id, content] = match;

        const textNodes = content.match(/>([^<]+)/g);

        if (textNodes) {
            result.push(
                // eslint-disable-next-line no-unsafe-optional-chaining
                ...textNodes?.map((node) => ({
                    value: node.substring(1).trim(),
                    id,
                })),
            );
        } else {
            result.push({
                value: content.trim(),
                id,
            });
        }
    }
    return result
        .filter((i) => i.value?.length > 1)
        .map((el) => ({
            title: /^\d/.test(el.value)
                ? el.value.replace(/^\d/, "").slice(1)
                : el.value,
            link: `#${el.id}`,
        }));
};

export const addStyleClassToTable = (body: string) => {
    if (body) {
        // start tag replace with <div className="table-responsive" > <table
        const firstSectionStartTag = body?.split("<table")[0]; // first section dose not contain <table to replace
        const bodyAfterReplaceStartTag = body
            ?.split("<table")
            .slice(1)
            .map((table) => {
                return `<div class="table-responsive"> <table ${table}`;
            });
        // final body after replace start tag
        const bodyAfterAddingStartTag = [
            firstSectionStartTag,
            ...bodyAfterReplaceStartTag,
        ].join(" ");

        // end tag replace with </table> </div>

        const firstSectionEndTag = bodyAfterAddingStartTag.split("</table>")[0];

        const bodyAfterReplaceEndTag = bodyAfterAddingStartTag
            .split("</table>")
            .slice(1)
            .map((table) => {
                return `</table></div>${table}`;
            });
        // final body after replace

        const finalBody = [firstSectionEndTag, ...bodyAfterReplaceEndTag].join(
            " ",
        );
        return finalBody;
    } else {
        return body;
    }
};

export const addStyleClassToIframe = (body: string) => {
    if (body) {
        // start tag replace with <div className="iframe-responsive" > <iframe
        const firstSectionStartTag = body?.split("<iframe")[0]; // first section dose not contain <iframe to replace
        const bodyAfterReplaceStartTag = body
            ?.split("<iframe")
            .slice(1)
            .map((iframe) => {
                return `<div class="iframe-responsive"> <iframe ${iframe}`;
            });
        // final body after replace start tag
        const bodyAfterAddingStartTag = [
            firstSectionStartTag,
            ...bodyAfterReplaceStartTag,
        ].join(" ");

        // end tag replace with </iframe> </div>

        const firstSectionEndTag =
            bodyAfterAddingStartTag.split("</iframe>")[0];

        const bodyAfterReplaceEndTag = bodyAfterAddingStartTag
            .split("</iframe>")
            .slice(1)
            .map((iframe) => {
                return `</iframe></div>${iframe}`;
            });
        // final body after replace

        const finalBody = [firstSectionEndTag, ...bodyAfterReplaceEndTag].join(
            " ",
        );
        return finalBody;
    } else {
        return body;
    }
};

export const addNoFollowToLinks = (body: string): string => {
    if (body) {
        const lines = body.split("\n");

        const updatedLines = lines.map((line) => {
            const updatedLine = line.replace(
                /<a\s+([^>]*)>/g,
                (match, attrs: string) => {
                    return !attrs.includes("several.com") && // links that contain several.com
                        !attrs.includes('href="/') && // internal links
                        !attrs.includes('href="#') // button to scroll to form has an href="#form-wrapper-id" to scroll to form
                        ? `<a ${attrs} rel="nofollow">`
                        : match;
                },
            );
            return updatedLine;
        });

        const updatedHtmlContent = updatedLines.join("\n");
        return updatedHtmlContent;
    }
    return body;
};

export const getCategoriesDescription = (
    category: GQLCategories,
    domain: GQLDomains,
    fallback?: string,
) => {
    if (category?.metatags) {
        for (let i = 0; i < category?.metatags.length; i++) {
            if (
                category?.metatags[i]?.key === "description" &&
                category?.metatags[i]?.value
            ) {
                return category?.metatags[i]?.value;
            }
        }
    }

    if (domain?.metatags) {
        for (let i = 0; i < domain.metatags.length; i++) {
            if (
                domain?.metatags[i]?.key === "description" &&
                domain?.metatags[i]?.value
            ) {
                return domain?.metatags[i]?.value;
            }
        }
    }

    if (category?.overview) {
        return category?.overview.replace(/<[^>]+>/g, "").substring(0, 160);
    }

    return fallback;
};
export const getCategoriesKeyWords = (
    category: GQLCategories,
    domain: GQLDomains,
) => {
    if (category && category?.metatags) {
        for (let i = 0; i < category?.metatags.length; i++) {
            if (
                category?.metatags[i]?.key === "keywords" &&
                category?.metatags[i]?.value
            ) {
                return category?.metatags[i]?.value;
            }
        }
    }
    if (domain && domain?.metatags) {
        for (let i = 0; i < domain?.metatags.length; i++) {
            if (
                domain?.metatags[i]?.key === "keywords" &&
                domain?.metatags[i]?.value
            ) {
                return domain?.metatags[i]?.value;
            }
        }
    }

    return "vpn , auto warranty";
};

export const setVisitorInfo = (session: Session): void => {
    const userInfo = {
        isBot:
            session?.visitDetails?.visitId === "bot-visit" ||
            session?.visitDetails?.visitorId === "bot-visitor",
        token: session?.accessToken as string,
        country: session?.visitDetails.country,
        region: session?.visitDetails.region,
    };
    saveToStorage("session", "visitorInfo", JSON.stringify(userInfo));
};

export const getVisitorInfo = (): VisitorInfo => {
    try {
        const userInfo = getFromStorage("session", "visitorInfo");
        if (userInfo && userInfo.length && userInfo.length > 0) {
            const parsedUserInfo: VisitorInfo = JSON.parse(
                userInfo,
            ) as VisitorInfo;
            return parsedUserInfo;
        }
        return {
            token: "",
            country: "",
            region: "",
            isBot: false,
        };
    } catch (error) {
        return {
            token: "",
            country: "",
            region: "",
            isBot: false,
        };
    }
};

export const getDirection = () => {
    return process.env.NEXT_PUBLIC_DEFAULT_LANG === "ar" ? "rtl" : "ltr";
};

export const extractExcerptFromBody = (body?: string, characters?: number) => {
    return body
        ?.replace(/<[^>]+>/g, "")
        .substring(0, characters)
        .trim();
};

export const handleUsPhoneNumberMasking = (value: string) => {
    let cleaned = value.replace(/[^0-9]/g, "");

    if (cleaned[0] === "1" && cleaned[1] === "1") {
        const x =
            cleaned.match(
                /([1]{0,1})(1{0,1})([0-9]{0,3})([0-9]{0,3})([0-9]{0,4})/,
            ) ?? "";

        return (
            (x[1] ? "1" : "") +
            (x[3].length >= 1 ? (x[1] ? " (" : "(") : "") +
            (x[3] ? x[3] : "") +
            (x[4].length >= 1 ? ") " : "") +
            (x[4] ? x[4] : "") +
            (x[5].length >= 1 ? "-" : "") +
            (x[5] ? x[5] : "")
        );
    } else {
        if (cleaned[0] && cleaned[0] !== "1") {
            cleaned = "1" + cleaned;
        }

        const x =
            cleaned.match(/([1]{0,1})([0-9]{0,3})([0-9]{0,3})([0-9]{0,4})/) ??
            "";

        return (
            (x[1] ? "1" : "") +
            (x[2].length >= 1 ? (x[1] ? " (" : "(") : "") +
            (x[2] ? x[2] : "") +
            (x[3].length >= 1 ? ") " : "") +
            (x[3] ? x[3] : "") +
            (x[4].length >= 1 ? "-" : "") +
            (x[4] ? x[4] : "")
        );
    }
};
const monthsArr = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

export function titleDecoder(title: string, args?: ArgsType): string {
    const newTitle = title
        .replace(/\[m\]/gi, monthsArr[new Date().getMonth()])
        .replace(/\[y\]/gi, String(new Date().getFullYear()))
        .replace(/\[s\]/gi, args?.region ?? "")
        .replace(/\[c\]/gi, args?.category ?? "");

    return newTitle;
}

export const resolveURL = (url: GQLUrls): string => {
    if (url) {
        if (url.isMain) return url.url as string;
        return url.main?.url as string;
    }
    return "";
};
export const replaceDkis = (
    text: string,
    allDkis: GQLDkis[] | undefined,
): string => {
    if (text) {
        const allowedDkis = allDkis
            ?.filter((dki) => dki.type === "allowed")
            ?.map((dki) => dki.name) as string[];

        const textArray = text.split("/");
        const urlAfterRemoveSlash = textArray[textArray.length - 1];
        const capitalizedWords: string[] = [];
        const words = urlAfterRemoveSlash.toLowerCase().split("-");

        words.forEach((word) => {
            const result = allowedDkis?.findIndex(
                (dki) => word.toLowerCase() === dki.toLowerCase(),
            );
            if (result >= 0) {
                capitalizedWords.push(allowedDkis[result]);
            } else {
                capitalizedWords.push(
                    word[0].toUpperCase() + word.slice(1, word.length),
                );
            }
        });

        return capitalizedWords.join(" ");
    }
    return text;
};

export const socialMediaShareLinks = [
    {
        icon: <Facebook />,
        link: "https://www.facebook.com/sharer/sharer.php?u=",
        name: "facebook",
    },
    {
        icon: <Twitter />,
        link: "https://twitter.com/share?url=",
        name: "twitter",
    },
    {
        icon: <Pinterest />,
        link: "http://pinterest.com/pin/create/button/?url=",
        name: "pinterest",
    },
];
export const socialMediaShareLinksMobile = [
    {
        icon: <FacebookMobile />,
        link: "https://www.facebook.com/sharer/sharer.php?u=",
        name: "facebook",
    },
    {
        icon: <TwitterMobile />,
        link: "https://twitter.com/share?url=",
        name: "twitter",
    },
    {
        icon: <PinterestMobile />,
        link: "http://pinterest.com/pin/create/button/?url=",
        name: "pinterest",
    },
];

type ArrayElement = number | string; // Adjust the types as needed

export const findIntersection = (
    ...arrays: Array<ArrayElement>[]
): ArrayElement[] => {
    return arrays.reduce((result, currentArray) => {
        return result.filter((element) => currentArray.includes(element));
    });
};

export const getReplacedPredefinedListAttributes = (
    attributes: GQLCategoryAttributesValues[],
    lookups: GQLLookupsByIdsQuery,
): GQLCategoryAttributesValues[] => {
    return attributes?.map((attr) => {
        if (
            attr?.baseAttribute?.type === "PredefinedList" &&
            lookups &&
            lookups.allLookups &&
            Array.isArray(lookups.allLookups)
        ) {
            const lookup = lookups.allLookups.find((lookup) => {
                let id: number = 0;
                try {
                    const parsed = JSON.parse(attr.value as string) as {
                        [x: string]: string;
                    };

                    id = parseInt(parsed.id);
                } catch (error) {
                    id = parseInt(attr.value as string, 10);
                }
                return lookup?.id === id;
            });
            return {
                ...attr,
                value: lookup?.label ?? attr.value,
            };
        }
        return attr;
    });
};

export const formatTimestamp = (timestamp: string) => {
    const isEpochString = /^\d+$/.test(timestamp);

    if (timestamp) {
        const date = new Date(
            isEpochString ? parseInt(timestamp, 10) : timestamp,
        );
        const options: Intl.DateTimeFormatOptions = {
            year: "numeric",
            month: "short",
            day: "numeric",
        };
        return new Intl.DateTimeFormat("en-US", options)?.format(date);
        return timestamp;
    }
};
